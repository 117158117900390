import Footer from "./components/Footer";
import React from "react";
import landingPage from "./images/landingPage.png";
import logo from "./images/logo.png";
import { Link } from "react-router-dom";

interface ButtonProps {
    href: string;
    variant: 'primary' | 'secondary' | 'accent';
    children: React.ReactNode;
    className?: string;
}

const Button: React.FC<ButtonProps> = ({ href, variant, children, className = '' }) => {
    const baseStyles = 'px-6 py-3 rounded-lg text-center text-base md:text-lg font-semibold transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg w-full sm:w-auto';

    const variantStyles = {
        primary: 'bg-green-600 text-white hover:bg-green-700',
        secondary: 'bg-red-500 text-white hover:bg-red-600',
        accent: 'bg-blue-500 text-white hover:bg-blue-600'
    };

    return (
        <a
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            className={`${baseStyles} ${variantStyles[variant]} ${className}`}
        >
            {children}
        </a>
    );
};

const HeroSection: React.FC = () => (
    <div className="flex flex-col md:flex-row gap-8 mb-8">
        <div className="md:w-1/2 flex flex-col justify-center">
            <h1 className="text-4xl md:text-6xl font-bold mb-4 text-green-700 leading-tight">
                Empowering Madagascar's Youth
            </h1>
            <p className="text-xl md:text-2xl mb-8 text-gray-700 leading-relaxed">
                Join us in building brighter futures through education, opportunity, and empowerment.
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                <Button
                    href="https://docs.google.com/forms/u/4/d/e/1FAIpQLSc_mVV9xrFb5qWyawM7HLD74-p7SAbGVkGz6rutZtuS_ykWCA/viewform?usp=send_form"
                    variant="primary"
                >
                    Start Your Journey
                </Button>
                <Button
                    href="https://docs.google.com/forms/u/4/d/e/1FAIpQLSc_mVV9xrFb5qWyawM7HLD74-p7SAbGVkGz6rutZtuS_ykWCA/viewform?usp=send_form"
                    variant="secondary"
                >
                    Make an Impact
                </Button>
                <Button
                    href="https://docs.google.com/forms/u/4/d/e/1FAIpQLSc_mVV9xrFb5qWyawM7HLD74-p7SAbGVkGz6rutZtuS_ykWCA/viewform?usp=send_form"
                    variant="accent"
                >
                    Join Our Team
                </Button>
            </div>
        </div>
        <div className="md:w-1/2">
            <img
                src={landingPage}
                alt="Empowering Madagascar Youth"
                className="w-full h-auto rounded-xl shadow-lg object-cover hover:shadow-xl transition-shadow duration-300"
            />
        </div>
    </div>
);

const MissionStatement: React.FC = () => (
    <div className="bg-gradient-to-r from-red-500 to-red-600 text-white p-8 rounded-xl mb-8 shadow-lg transform hover:scale-[1.02] transition-transform duration-300">
        <p className="text-xl leading-relaxed">
            We are a dedicated non-profit organization focused on transforming lives through education,
            professional development, and leadership opportunities. Our mission is to empower Madagascar's youth,
            with a special focus on women, to become leaders of tomorrow. Through our programs, we're creating
            pathways to success and building a stronger future for Madagascar.
        </p>
    </div>
);

const LandingPage: React.FC = () => {
    return (
        <div className="bg-gray-50 min-h-screen">
            <div className="bg-white shadow-xl p-6 md:p-12 max-w-7xl mx-auto my-8 rounded-xl">
                <header className="flex justify-between items-center mb-12">
                    <Link
                        to="/home"
                        className="flex items-center space-x-3 hover:opacity-90 transition-opacity"
                    >
                        <img src={logo} alt="EMY Logo" className="h-14 md:h-20" />
                    </Link>
                </header>

                <HeroSection />
                <MissionStatement />
                <Footer />
            </div>
        </div>
    );
};

export default LandingPage;